export const ROUTES = {
  // AUTH
  main: '/',
  login: '/login',
  passwordReset: '/password-reset',
  verifyAccount: '/verifyaccount/[uuidb64]/[token]',

  // DASHBOARD
  dashboard: '/dashboard',

  //PROFILE PAGE
  settings: '/profile/settings',
  changePassword: '/profile/change-password',
  createToken: '/profile/create-token',

  // SECURITY PAGE
  createUser: '/security/users/invite-new-user',
  users: '/security/users',
  editUser: (userId: string) => `/security/users/${userId}/edit-user`,
  editRole: (roleId: string) => `/security/roles/${roleId}/edit-role`,
  createRole: '/security/roles/new-role',
  roles: '/security/roles',
  auditTrails: '/security/audit-trails',
  buckets: '/security/buckets',
  createBucket: '/security/new-bucket',
  editBucket: (bucketId: string) => `/security/buckets/${bucketId}/`,
  credentials: '/security/credentials',
  createCredential: '/security/new-credential',
  editCredential: (credentialId: string) => `/security/credentials/${credentialId}/`,
  pendingInvites: '/security/pending-invites',

  // DATA PAGE
  dataTables: '/data/tables',
  singleTable: '/data/tables/',
  createDataTable: '/data/new-table',
  createSummaryTable: '/data/new-summary-table',
  createSummaryTableColumnAnalysis: '/data/new-summary-table/column-analysis',
  editSummaryTableSettingsColumnAnalysis: (tableId: string) =>
    `/data/tables/${tableId}/edit-summary-settings/column-analysis`,
  editSummaryTableSettings: (tableId: string) => `/data/tables/${tableId}/edit-summary-settings`,
  dataDictionaries: '/data/dictionaries',
  createDataDictionaries: '/data/dictionaries/new-data-dictionary',
  dataFunctions: '/data/functions',
  dataFiles: '/data/files',
  createDataFile: '/data/files/new-file',
  createDataFunctions: '/data/functions/new-custom-function',
  createIngestSource: '/data/new-ingest-source',
  createTransform: '/data/new-transform',
  editSingleTable: (tableId: string) => `/data/tables/${tableId}`,
  editIngestSource: (tableId: string, type: string, uuid: string | number) =>
    `/data/tables/${tableId}/source/edit/${type}/${uuid}`,
  editTransform: (tableId: string, uuid: string | number) =>
    `/data/tables/${tableId}/transform/edit/${uuid}`,
  editFile: (projectId: string, name: string) => `/data/files/${projectId}/${name}/update-file`,
  validateTransform: '/data/validate-transform',
  editDictionary: (projectId: string, dictionaryId: string) =>
    `/data/dictionaries/${projectId}/${dictionaryId}/edit-dictionary`,
  editFunction: (projectId: string, functionId: string) =>
    `/data/functions/${projectId}/${functionId}/edit-function`,

  // SCALE PAGE
  scaleTables: '/scale',
  scaleSearchTable: '/scale/search',
  scaleCore: '/scale/core',
  scalePool: '/scale/pool/create',
  scalePoolEdit: (id: string) => `/scale/pool/${id}`,

  // JOBS
  alterNew: '/jobs/alter-jobs/create',
  batchNew: '/jobs/batch-jobs/create',
  alterTables: '/jobs/alter-jobs',
  batchTable: '/jobs/batch-jobs',

  // QUERY
  query: '/queries',
  queryReference: (selectedTableStore?: IFormTableListItem | null) =>
    selectedTableStore
      ? `/queries/reference/?table_id=${selectedTableStore?.table_id}&data=${selectedTableStore?.data}`
      : '/queries/reference',
  queryResult: '/result',
  queryStatistics: '/result',

  // SUPPORT
  support: '/support',

  // System Health
  systemHealthCounts: '/system-health/counts',
  systemHealthLogs: '/system-health/logs',
};
